<template>
  <nav
    ref="header"
    class="nav-menu"
    :class="{
      '--open': openOverlay,
      '--scrolling': isScrolling,
      '--light': isLight && !isScrolling,
    }"
  >
    <div class="row-40-25 nav-menu__container">
      <NavigationLarge
        v-if="matchMedia"
        class="nav-menu__large"
        :is-light="isLight && !isScrolling"
        :external="external"
      />

      <NavigationSmall
        v-if="!matchMedia"
        class="nav-menu__small"
        @open-menu="toggleOverlay"
        :open="openOverlay"
        :is-light="isLight && !isScrolling"
        :external="external"
      />
    </div>

    <!-- Overlay -->
    <WidgetOverlayFullScreen
      class="nav-menu__overlay hide-for-large"
      ref="overlay"
      :right="true"
    >
      <div class="nav-menu__overlay__wrap" data-lenis-prevent>
        <!-- <button class="nav-menu__overlay__close-btn" @click="toggleOverlay">
          <span class="text-sans-s is-text-uppercase">{{ $t('cta.close') }}</span>
          <img
            src="@/assets/img/ico/ico-close-white.svg"
            ref="closeButton"
            alt="Close"
            class="nav-menu__overlay__close"
          />
        </button> -->

        <NavigationMenuLinks
          class="nav-menu__links --main"
          ref="mainMenu"
          :links="[]"
          :corporate="corporateData"
          :external="external"
          @open-sub-menu="toggleSubMenu"
        />
      </div>
    </WidgetOverlayFullScreen>
  </nav>
</template>

<script>
export default {
  name: 'NavigationMenu',
}
</script>

<script setup>
import { gsap } from 'gsap'
import { debounce } from '~/assets/js/utils.js'

import { getCorporateData } from '@/graphql/dato/queries/footer.js'

const { locale } = useI18n()

const matchMedia = useHelpers().evalMatchMedia('(min-width: 1000px)')
const route = useRoute()

const lightTheme = setLightTheme()

const isLight = ref(false)

const overlay = ref(null)
const mainMenu = ref(null)
const openOverlay = ref(false)
const subMenuExpertise = ref(null)
const subMenuTechnology = ref(null)
const showSubMenu = ref(false)

// Hide / Show nav
const hideMenu = ref(false)
const lastScrollPosition = ref(0)
const header = ref(null)
const isTop = ref(true)

const tl = ref(null)

const props = defineProps({
  external: {
    type: Boolean,
    default: false,
  },
})

watch(
  () => lightTheme.value,
  (lightTheme) => {
    if (lightTheme) {
      isLight.value = true
    } else {
      isLight.value = false
    }
  },
)

// Gestisco Overlay
const toggleOverlay = () => {
  if (tl.value?.isActive()) {
    tl.value.progress(1)
  }
  openOverlay.value = !openOverlay.value
}

watch(
  () => openOverlay.value,
  (openOverlay) => {
    tl.value = openOverlay
      ? animateOverlayIn().duration(1)
      : animateOverlayOut().duration(1.5)
    tl.value.play()
    showSubMenu.value = null
  },
)

watch(
  () => route.path,
  () => {
    if (openOverlay.value) {
      toggleOverlay()
    }
    if (route?.name?.includes('contacts')) {
      if (document.body.classList?.contains('--light-theme')) {
        lightTheme.value = true
      } else {
        document.body.classList?.add('--light-theme')
        lightTheme.value = true
      }
    } else {
      document.body.classList?.remove('--light-theme')
      lightTheme.value = false
    }
  },
)

// Gestisco Submenu
const toggleSubMenu = (action) => {
  showSubMenu.value = action
}

// Animazioni
const animateOverlayIn = () => {
  const tl = gsap.timeline({
    paused: true,
    duration: 1.5,

    onStart: () => {
      document.body.setAttribute('data-menu-overlay-open', true)
    },
    onComplete: () => {
      tl.kill()
    },
  })

  tl.add(overlay.value.animateIn().play(), '<')
  if (mainMenu.value) {
    tl.add(mainMenu.value.animateIn().play())
  }

  return tl
}

const animateOverlayOut = () => {
  const tl = gsap.timeline({
    paused: true,
    duration: 3,
    onComplete: () => {
      document.body.removeAttribute('data-menu-overlay-open', false)

      tl.kill()
    },
  })

  tl.add(overlay.value.animateOut().play(), '<')

  if (mainMenu.value) {
    tl.add(mainMenu.value.animateOut().play())
  }
  if (showSubMenu.value === 'expertise' || showSubMenu.value === 'technology') {
    tl.add(subMenuExpertise.value.animateOut().play())
  }

  return tl
}

const isScrolling = computed(() => !isTop.value && !hideMenu.value)

const handleScroll = () => {
  const menuHeight = header.value.getBoundingClientRect().height
  const currentScrollPosition =
    window.scrollY || document.documentElement.scrollTop

  if (
    currentScrollPosition > lastScrollPosition.value &&
    currentScrollPosition > menuHeight
  ) {
    // Scrolling down
    hideMenu.value = true
    header.value.classList.add('--hide-nav')
    isTop.value = false
  } else {
    // Scrolling up
    if (currentScrollPosition <= menuHeight) {
      isTop.value = true
    }
    hideMenu.value = false
    header.value.classList.remove('--hide-nav')
  }
  lastScrollPosition.value =
    currentScrollPosition <= 0 ? 0 : currentScrollPosition
}

// Corporate data queries (from DatoCms)
const variables = computed(() => ({
  locale: locale?.value ? locale.value : 'it',
}))
const { data } = await useGraphql(getCorporateData, variables)
if (!data.value?.corporate) {
  throw createError({ statusCode: 404 })
}
const corporateData = computed(() =>
  data?.value?.corporate ? data?.value?.corporate : null,
)

onMounted(() => {
  window.addEventListener('resize', debounce(onResize))
  window.addEventListener('scroll', handleScroll)
  onResize()
})

onUnmounted(() => {
  window.removeEventListener('resize', onResize)
  window.removeEventListener('scroll', handleScroll)
  clearTimeout(closeTimeout)
})

const onResize = () => {
  openOverlay.value = false
}
</script>

<style lang="scss">
@import './style.scss';
</style>
