export const getSitemapData = `
  query GetSitemapData(
  $locale: SiteLocale 
  $first: IntType
  ) {
    allMenuCategories(locale: $locale, first: $first) {
      id
      slug
      _allSlugLocales {
        value
        locale
      }
    }
    allEvents(locale: $locale, first: $first) {
      id
      slug
      _allSlugLocales {
        value
        locale
      }
    }
  }
`
