<template>
  <div>
    <NuxtPwaManifest />
    <NuxtLayout>
      <NuxtLoadingIndicator />
      <NuxtPage />
    </NuxtLayout>
    <div class="preloader-curtain" data-animate="preloader-curtain"></div>
  </div>
</template>

<script setup>
import { useDatoStore } from '@/store/dato.js'
const formKitConfig = inject(Symbol.for('FormKitConfig'))
const { locale } = useI18n()

// TODO: Evitare il watch e quando rispondono implementare https://github.com/nuxt-modules/apollo/discussions/529
await useAsyncData(() => useDatoStore().fetchConfiguration())

watch(
  () => locale.value,
  async () => {
    await useAsyncData(() => useDatoStore().fetchConfiguration())
  },
)

// Controllo sul locale attuale (per Formkit..)
const actualLocale = locale.value ? locale.value : 'it'
if (formKitConfig?.locale) {
  formKitConfig.locale = actualLocale
}

const { lenis } = useLenis()
provide('lenis', lenis)
</script>
