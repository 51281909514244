import { default as indexzykjRX74mxMeta } from "/opt/build/repo/pages/contatti/form-failure/index.vue?macro=true";
import { default as indexeVcaUwsYG2Meta } from "/opt/build/repo/pages/contatti/form-success/index.vue?macro=true";
import { default as indexU71mUg00ZkMeta } from "/opt/build/repo/pages/contatti/index.vue?macro=true";
import { default as cookie_45policyZcRIsfqfIvMeta } from "/opt/build/repo/pages/cookie-policy.vue?macro=true";
import { default as index38Qe4v2f9JMeta } from "/opt/build/repo/pages/event/[slug]/index.vue?macro=true";
import { default as gift_45voucher_45okuK6LR0K27KMeta } from "/opt/build/repo/pages/gift-voucher-ok.vue?macro=true";
import { default as i_45tuoi_45eventiZxOrvAgFJuMeta } from "/opt/build/repo/pages/i-tuoi-eventi.vue?macro=true";
import { default as in_45cucinakbvAFWb3zwMeta } from "/opt/build/repo/pages/in-cucina.vue?macro=true";
import { default as in_45salaBxJ66yZbPXMeta } from "/opt/build/repo/pages/in-sala.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as indexWDlKG7jNkeMeta } from "/opt/build/repo/pages/lavora-con-noi/form-failure/index.vue?macro=true";
import { default as indexqdweRDTAsKMeta } from "/opt/build/repo/pages/lavora-con-noi/form-success/index.vue?macro=true";
import { default as indexszsEXRj0ozMeta } from "/opt/build/repo/pages/lavora-con-noi/index.vue?macro=true";
import { default as oggi_45come_45ieriQeRITWsOcwMeta } from "/opt/build/repo/pages/oggi-come-ieri.vue?macro=true";
import { default as index6d9pOwjzxWMeta } from "/opt/build/repo/pages/piatti-e-vini/[category]/[slug]/index.vue?macro=true";
import { default as indexI6HOpdyw1mMeta } from "/opt/build/repo/pages/piatti-e-vini/[category]/index.vue?macro=true";
import { default as indexbyD3VfqYP5Meta } from "/opt/build/repo/pages/piatti-e-vini/index.vue?macro=true";
import { default as indexHF6CF2bw7jMeta } from "/opt/build/repo/pages/prenota/form-failure/index.vue?macro=true";
import { default as indexoyMIgy9yrWMeta } from "/opt/build/repo/pages/prenota/form-success/index.vue?macro=true";
import { default as indexFZqPVOhhzbMeta } from "/opt/build/repo/pages/prenota/index.vue?macro=true";
import { default as indexKCqVZI0adVMeta } from "/opt/build/repo/pages/print-menu-en/index.vue?macro=true";
import { default as indexqfQhLOtDHUMeta } from "/opt/build/repo/pages/print-menu/index.vue?macro=true";
import { default as indexk3c0JEv7zqMeta } from "/opt/build/repo/pages/printed-menu/index.vue?macro=true";
import { default as privacy_45policyQXTlhFuZ55Meta } from "/opt/build/repo/pages/privacy-policy.vue?macro=true";
import { default as regala_45una_45cenatVJ3xG0oLmMeta } from "/opt/build/repo/pages/regala-una-cena.vue?macro=true";
import { default as take_45awayfXHEe0MbrVMeta } from "/opt/build/repo/pages/take-away.vue?macro=true";
import { default as component_45stubRU5sOBTecHMeta } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubRU5sOBTecH } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "contatti-form-failure___it",
    path: "/contatti/errore-di-invio",
    meta: indexzykjRX74mxMeta || {},
    component: () => import("/opt/build/repo/pages/contatti/form-failure/index.vue").then(m => m.default || m)
  },
  {
    name: "contatti-form-failure___en",
    path: "/en/contacts/submission-error",
    meta: indexzykjRX74mxMeta || {},
    component: () => import("/opt/build/repo/pages/contatti/form-failure/index.vue").then(m => m.default || m)
  },
  {
    name: "contatti-form-success___it",
    path: "/contatti/modulo-inviato",
    meta: indexeVcaUwsYG2Meta || {},
    component: () => import("/opt/build/repo/pages/contatti/form-success/index.vue").then(m => m.default || m)
  },
  {
    name: "contatti-form-success___en",
    path: "/en/contacts/form-sent",
    meta: indexeVcaUwsYG2Meta || {},
    component: () => import("/opt/build/repo/pages/contatti/form-success/index.vue").then(m => m.default || m)
  },
  {
    name: "contatti___it",
    path: "/contatti",
    meta: indexU71mUg00ZkMeta || {},
    component: () => import("/opt/build/repo/pages/contatti/index.vue").then(m => m.default || m)
  },
  {
    name: "contatti___en",
    path: "/en/contacts",
    meta: indexU71mUg00ZkMeta || {},
    component: () => import("/opt/build/repo/pages/contatti/index.vue").then(m => m.default || m)
  },
  {
    name: "cookie-policy___it",
    path: "/cookie-policy",
    meta: cookie_45policyZcRIsfqfIvMeta || {},
    component: () => import("/opt/build/repo/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: "cookie-policy___en",
    path: "/en/cookie-policy",
    meta: cookie_45policyZcRIsfqfIvMeta || {},
    component: () => import("/opt/build/repo/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: "event-slug___it",
    path: "/event/:slug()",
    meta: index38Qe4v2f9JMeta || {},
    component: () => import("/opt/build/repo/pages/event/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "event-slug___en",
    path: "/en/event/:slug()",
    meta: index38Qe4v2f9JMeta || {},
    component: () => import("/opt/build/repo/pages/event/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "gift-voucher-ok___it",
    path: "/gift-voucher-ok",
    meta: gift_45voucher_45okuK6LR0K27KMeta || {},
    component: () => import("/opt/build/repo/pages/gift-voucher-ok.vue").then(m => m.default || m)
  },
  {
    name: "gift-voucher-ok___en",
    path: "/en/gift-voucher-ok",
    meta: gift_45voucher_45okuK6LR0K27KMeta || {},
    component: () => import("/opt/build/repo/pages/gift-voucher-ok.vue").then(m => m.default || m)
  },
  {
    name: "i-tuoi-eventi___it",
    path: "/i-tuoi-eventi",
    meta: i_45tuoi_45eventiZxOrvAgFJuMeta || {},
    component: () => import("/opt/build/repo/pages/i-tuoi-eventi.vue").then(m => m.default || m)
  },
  {
    name: "i-tuoi-eventi___en",
    path: "/en/your-events",
    meta: i_45tuoi_45eventiZxOrvAgFJuMeta || {},
    component: () => import("/opt/build/repo/pages/i-tuoi-eventi.vue").then(m => m.default || m)
  },
  {
    name: "in-cucina___it",
    path: "/in-cucina",
    meta: in_45cucinakbvAFWb3zwMeta || {},
    component: () => import("/opt/build/repo/pages/in-cucina.vue").then(m => m.default || m)
  },
  {
    name: "in-cucina___en",
    path: "/en/into-the-kitchen",
    meta: in_45cucinakbvAFWb3zwMeta || {},
    component: () => import("/opt/build/repo/pages/in-cucina.vue").then(m => m.default || m)
  },
  {
    name: "in-sala___it",
    path: "/in-sala",
    meta: in_45salaBxJ66yZbPXMeta || {},
    component: () => import("/opt/build/repo/pages/in-sala.vue").then(m => m.default || m)
  },
  {
    name: "in-sala___en",
    path: "/en/indoors",
    meta: in_45salaBxJ66yZbPXMeta || {},
    component: () => import("/opt/build/repo/pages/in-sala.vue").then(m => m.default || m)
  },
  {
    name: "index___it",
    path: "/",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "lavora-con-noi-form-failure___it",
    path: "/lavora-con-noi/errore-di-invio",
    meta: indexWDlKG7jNkeMeta || {},
    component: () => import("/opt/build/repo/pages/lavora-con-noi/form-failure/index.vue").then(m => m.default || m)
  },
  {
    name: "lavora-con-noi-form-failure___en",
    path: "/en/work-with-us/submission-error",
    meta: indexWDlKG7jNkeMeta || {},
    component: () => import("/opt/build/repo/pages/lavora-con-noi/form-failure/index.vue").then(m => m.default || m)
  },
  {
    name: "lavora-con-noi-form-success___it",
    path: "/lavora-con-noi/modulo-inviato",
    meta: indexqdweRDTAsKMeta || {},
    component: () => import("/opt/build/repo/pages/lavora-con-noi/form-success/index.vue").then(m => m.default || m)
  },
  {
    name: "lavora-con-noi-form-success___en",
    path: "/en/work-with-us/form-sent",
    meta: indexqdweRDTAsKMeta || {},
    component: () => import("/opt/build/repo/pages/lavora-con-noi/form-success/index.vue").then(m => m.default || m)
  },
  {
    name: "lavora-con-noi___it",
    path: "/lavora-con-noi",
    meta: indexszsEXRj0ozMeta || {},
    component: () => import("/opt/build/repo/pages/lavora-con-noi/index.vue").then(m => m.default || m)
  },
  {
    name: "lavora-con-noi___en",
    path: "/en/work-with-us",
    meta: indexszsEXRj0ozMeta || {},
    component: () => import("/opt/build/repo/pages/lavora-con-noi/index.vue").then(m => m.default || m)
  },
  {
    name: "oggi-come-ieri___it",
    path: "/oggi-come-ieri",
    meta: oggi_45come_45ieriQeRITWsOcwMeta || {},
    component: () => import("/opt/build/repo/pages/oggi-come-ieri.vue").then(m => m.default || m)
  },
  {
    name: "oggi-come-ieri___en",
    path: "/en/today-as-yesterday",
    meta: oggi_45come_45ieriQeRITWsOcwMeta || {},
    component: () => import("/opt/build/repo/pages/oggi-come-ieri.vue").then(m => m.default || m)
  },
  {
    name: "piatti-e-vini-category-slug___it",
    path: "/piatti-e-vini/:category()/:slug()",
    meta: index6d9pOwjzxWMeta || {},
    component: () => import("/opt/build/repo/pages/piatti-e-vini/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "piatti-e-vini-category-slug___en",
    path: "/en/dishes-and-wines/:category()/:slug()",
    meta: index6d9pOwjzxWMeta || {},
    component: () => import("/opt/build/repo/pages/piatti-e-vini/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "piatti-e-vini-category___it",
    path: "/piatti-e-vini/:category()",
    meta: indexI6HOpdyw1mMeta || {},
    component: () => import("/opt/build/repo/pages/piatti-e-vini/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "piatti-e-vini-category___en",
    path: "/en/dishes-and-wines/:category()",
    meta: indexI6HOpdyw1mMeta || {},
    component: () => import("/opt/build/repo/pages/piatti-e-vini/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "piatti-e-vini___it",
    path: "/piatti-e-vini",
    meta: indexbyD3VfqYP5Meta || {},
    component: () => import("/opt/build/repo/pages/piatti-e-vini/index.vue").then(m => m.default || m)
  },
  {
    name: "piatti-e-vini___en",
    path: "/en/dishes-and-wines",
    meta: indexbyD3VfqYP5Meta || {},
    component: () => import("/opt/build/repo/pages/piatti-e-vini/index.vue").then(m => m.default || m)
  },
  {
    name: "prenota-form-failure___it",
    path: "/prenota/errore-di-invio",
    meta: indexHF6CF2bw7jMeta || {},
    component: () => import("/opt/build/repo/pages/prenota/form-failure/index.vue").then(m => m.default || m)
  },
  {
    name: "prenota-form-failure___en",
    path: "/en/book/submission-error",
    meta: indexHF6CF2bw7jMeta || {},
    component: () => import("/opt/build/repo/pages/prenota/form-failure/index.vue").then(m => m.default || m)
  },
  {
    name: "prenota-form-success___it",
    path: "/prenota/modulo-inviato",
    meta: indexoyMIgy9yrWMeta || {},
    component: () => import("/opt/build/repo/pages/prenota/form-success/index.vue").then(m => m.default || m)
  },
  {
    name: "prenota-form-success___en",
    path: "/en/book/form-sent",
    meta: indexoyMIgy9yrWMeta || {},
    component: () => import("/opt/build/repo/pages/prenota/form-success/index.vue").then(m => m.default || m)
  },
  {
    name: "prenota___it",
    path: "/prenota",
    meta: indexFZqPVOhhzbMeta || {},
    component: () => import("/opt/build/repo/pages/prenota/index.vue").then(m => m.default || m)
  },
  {
    name: "prenota___en",
    path: "/en/book-online",
    meta: indexFZqPVOhhzbMeta || {},
    component: () => import("/opt/build/repo/pages/prenota/index.vue").then(m => m.default || m)
  },
  {
    name: "print-menu-en___it",
    path: "/print-menu-en",
    meta: indexKCqVZI0adVMeta || {},
    component: () => import("/opt/build/repo/pages/print-menu-en/index.vue").then(m => m.default || m)
  },
  {
    name: "print-menu-en___en",
    path: "/en/print-menu-en",
    meta: indexKCqVZI0adVMeta || {},
    component: () => import("/opt/build/repo/pages/print-menu-en/index.vue").then(m => m.default || m)
  },
  {
    name: "print-menu___it",
    path: "/print-menu",
    meta: indexqfQhLOtDHUMeta || {},
    component: () => import("/opt/build/repo/pages/print-menu/index.vue").then(m => m.default || m)
  },
  {
    name: "print-menu___en",
    path: "/en/print-menu",
    meta: indexqfQhLOtDHUMeta || {},
    component: () => import("/opt/build/repo/pages/print-menu/index.vue").then(m => m.default || m)
  },
  {
    name: "printed-menu___it",
    path: "/printed-menu",
    meta: indexk3c0JEv7zqMeta || {},
    component: () => import("/opt/build/repo/pages/printed-menu/index.vue").then(m => m.default || m)
  },
  {
    name: "printed-menu___en",
    path: "/en/printed-menu",
    meta: indexk3c0JEv7zqMeta || {},
    component: () => import("/opt/build/repo/pages/printed-menu/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___it",
    path: "/privacy-policy",
    meta: privacy_45policyQXTlhFuZ55Meta || {},
    component: () => import("/opt/build/repo/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___en",
    path: "/en/privacy-policy",
    meta: privacy_45policyQXTlhFuZ55Meta || {},
    component: () => import("/opt/build/repo/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "regala-una-cena___it",
    path: "/regala-una-cena",
    meta: regala_45una_45cenatVJ3xG0oLmMeta || {},
    component: () => import("/opt/build/repo/pages/regala-una-cena.vue").then(m => m.default || m)
  },
  {
    name: "regala-una-cena___en",
    path: "/en/gift-a-dinner",
    meta: regala_45una_45cenatVJ3xG0oLmMeta || {},
    component: () => import("/opt/build/repo/pages/regala-una-cena.vue").then(m => m.default || m)
  },
  {
    name: "take-away___it",
    path: "/take-away",
    meta: take_45awayfXHEe0MbrVMeta || {},
    component: () => import("/opt/build/repo/pages/take-away.vue").then(m => m.default || m)
  },
  {
    name: "take-away___en",
    path: "/en/take-away",
    meta: take_45awayfXHEe0MbrVMeta || {},
    component: () => import("/opt/build/repo/pages/take-away.vue").then(m => m.default || m)
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/menu",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/menu-en",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/menu-de",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/menu-ru",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/la-famiglia/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/the-family/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/die-familie/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/the-family-ru/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/carta-vini/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/home-en/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/home-de/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/home-ru/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/il-ristorante/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/the-restaurant/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/das-restaurant/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/the-restaurant-ru/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/news",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/prenota-online",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/book-online",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/prenota-evento",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubRU5sOBTecH
  }
]