<template>
  <div class="text-header" :class="{ '--alternative': isAlternative }">
    <template v-if="title.includes('<h1>') || title.includes('<p>')">
      <div
        v-html="title"
        class="text-header__title is-text-center title-xl-1"
        data-animate="preloader-reveal"
      />
    </template>
    <template v-else-if="title">
      <h1 class="text-header__title title-xl-1" data-animate="preloader-reveal">
        {{ title }}
      </h1>
    </template>
    <div
      v-if="subtitle"
      v-html="subtitle"
      data-animate="preloader-opacity"
      class="text-header__subtitle text-corpo-xxl p-top-m-1"
    />
    <div
      v-if="intro"
      data-animate="preloader-opacity"
      class="text-heder__intro text-corpo-l"
      v-html="intro"
    />
  </div>
</template>

<script>
export default {
  name: 'PagesCommonTextHeader',
}
</script>

<script setup>
const props = defineProps({
  title: String,
  intro: String,
  subtitle: String,
  isAlternative: {
    type: Boolean,
    default: false,
  },
})
</script>

<style lang="scss">
@import './style.scss';
</style>
