import gql from 'graphql-tag'

export const getCorporateData = gql`
  query GetCorporateData($locale: SiteLocale) {
    corporate(locale: $locale) {
      id
      generalInfo
      additionalInfo
      bookingInfo {
        id
        text
      }
      socialLinks {
        id
        text
      }
    }
  }
`
