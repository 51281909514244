<template>
  <div>
    <NuxtLayout name="error">
      <div>
        <WidgetMouseCursor />
        <NavigationMenu :external="true" />

        <div
          class="page page-error p-top-xxxl-1 p-bottom-xxxl-1 is-text-center"
        >
          <div>
            <PagesCommonTextHeader
              :title="
                error.statusCode === 500
                  ? $t('errors.500.title')
                  : $t('errors.404.title')
              "
            />

            <div
              data-animate="preloader-opacity"
              class="row-40-25 m-top-xl-1 is-text-center"
            >
              <WidgetArrowLink
                :label="$t('cta.backToHome')"
                :link="localePath({ name: 'index' })"
              />
            </div>
          </div>
        </div>

        <FooterMain :external="true" />
      </div>
    </NuxtLayout>
    <div class="preloader-curtain" data-animate="preloader-curtain"></div>
  </div>
</template>

<script setup>
import pageTransition from '@/assets/js/transitions/helper.js'

definePageMeta({
  pageTransition,
})

const fromError = setFromError()

const props = defineProps({
  error: Object,
})
console.error(props.error)

onMounted(() => {
  console.log(fromError.value)
})

onUnmounted(() => {
  fromError.value = true
  console.log(fromError.value)
})

// Inizializzo lo scroller e lo inietto nella app ion modo da averlo sempre accessibile
const { lenis } = useLenis()
provide('lenis', lenis)
</script>
