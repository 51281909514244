<template>
  <div class="nav-small__wrap flex is-justify-space-between">
    <!-- Logo -->
    <NuxtLink
      :to="localePath({ name: 'index' })"
      :external="external"
      class="nav-small__logo"
    >
      <img
        :src="isLight ? GlyphLogoWhite : GlyphLogo"
        class="nav-small__logo-glyph"
        width="106"
        height="42"
        :alt="runtimeConfig.public.siteName"
      />
    </NuxtLink>

    <div class="nav-small__list__wrap">
      <!-- Button -->
      <button
        ref="menu"
        class="text-sans-s is-text-uppercase"
        @click="openOverlay"
      >
        {{ props.open ? $t('cta.close') : $t('menu.menu-nav.menu') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavigationMenuSmall',
}
</script>

<script setup>
import GlyphLogo from '@/assets/img/logos/logo-mobile-black.svg'
import GlyphLogoWhite from '@/assets/img/logos/logo-mobile-white.svg'

const runtimeConfig = useRuntimeConfig()

const props = defineProps({
  isLight: Boolean,
  open: Boolean,
  external: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['open-menu'])

// Gestisco Overlay
const openOverlay = () => {
  emit('open-menu')
}
</script>

<style lang="scss">
@import './style.scss';
</style>
