import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

import queries from '@/graphql/dato/queries/index.js'

export const useDatoStore = defineStore('dato', () => {
  const { locale } = useI18n()
  const runtimeConfig = useRuntimeConfig()
  const menuUtils = useMenuUtils()

  // URL per le chiamate per i menu
  const URL_BASE_JSON_MENU = runtimeConfig.public.urlBaseJsonMenu

  // Store state
  const seoFallback = ref({})
  const menuCategories = ref([])
  const menuProducts = ref([])
  const menuTree = ref([])
  const menusCustom = ref([])

  // Store getters
  const configuration = computed(() => ({
    seoFallback: seoFallback.value,
    menuCategories: menuCategories.value,
    menuProducts: menuProducts.value,
    menuTree: menuTree.value,
  }))

  // Store actions
  const fetchConfiguration = async () => {
    try {
      const { data } = await useAsyncQuery(queries.dato.datoState, {
        locale: locale?.value ? locale.value : 'it',
      })

      // Chiamiamo le categorie dei menu del gestionale...
      let categorieJson = null
      try {
        categorieJson = await fetch(`${URL_BASE_JSON_MENU}/da-guido-categorie/`)
      } catch (error) {
        console.log(error)
      }

      const parsedCategories = categorieJson ? await categorieJson.json() : []

      // ...la lista prodotti generale...
      let prodottiJson = null
      try {
        prodottiJson = await fetch(`${URL_BASE_JSON_MENU}/da-guido-prodotti/`)
      } catch (error) {
        console.log(error)
      }
      const parsedProducts = prodottiJson ? await prodottiJson.json() : []

      // ...i menu custom a prodotti...
      /* let menuProdottiJson = null
      try {
        menuProdottiJson = await fetch(
          `${URL_BASE_JSON_MENU}/da-guido-menu-prodotti/`,
        )
      } catch (error) {
        console.log(error)
      }
      const parsedMenuProdotti = menuProdottiJson
        ? await menuProdottiJson.json()
        : []

      // ...i menu custom a prezzo fisso...
      let menuPrezzoFissoJson = null
      try {
        menuPrezzoFissoJson = await fetch(
          `${URL_BASE_JSON_MENU}/da-guido-menu-prezzo-fisso/`,
        )
      } catch (error) {
        console.log(error)
      }
      const parsedMenuPrezzoFisso = menuPrezzoFissoJson
        ? await menuPrezzoFissoJson.json()
        : []
      */

      // ...e un array con tutti i menu
      let allMenusJson = null
      try {
        allMenusJson = await fetch(`${URL_BASE_JSON_MENU}/da-guido-tutti-menu/`)
      } catch (error) {
        console.log(error)
      }
      const parsedAllMenus = allMenusJson ? await allMenusJson.json() : []

      const allMenus = parsedAllMenus?.menu || []

      // Inizializziamo infine i valori dello state
      menuCategories.value = parsedCategories || []
      menuProducts.value = parsedProducts || []
      const testMenus = menuUtils.buildMenusCustom(allMenus)
      menusCustom.value = menuUtils.createCartMenu(testMenus)
      console.log(menusCustom.value)
      const categoriesTree = menuCategories.value?.categorie?.length
        ? menuUtils.buildCategoriesTree(menuCategories.value)
        : []
      const categoriesAndProductsTree = categoriesTree.length
        ? menuUtils.addProductsToTree(
            categoriesTree,
            menuProducts?.value?.prodotti,
          )
        : []
      menuTree.value = categoriesAndProductsTree
      seoFallback.value = data.value?._site?.globalSeo
    } catch (e) {
      console.error(e)
    }
  }

  return {
    seoFallback,
    menuCategories,
    menuProducts,
    menusCustom,
    menuTree,
    configuration,
    fetchConfiguration,
  }
})
