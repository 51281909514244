<template>
  <div
    class="overlay-full-screen"
    data-lenis-prevent
    ref="overlay"
    data-animate="overlay"
    :class="{ '--no-bg-anim': noBgAnimation }"
  >
    <div
      class="overlay-full-screen__background"
      data-animate="overlay-background"
      :class="{ '--right': right }"
    />
    <div class="overlay-full-screen__content" data-animate="overlay-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'WidgetOverlayFullScreen',
}
</script>

<script setup>
import { gsap } from 'gsap'

const emit = defineEmits(['animation-update', 'animation-reverse-complete'])

const props = defineProps({
  show: Boolean,
  right: Boolean,
  noBgAnimation: Boolean,
})

const overlay = ref(null)
const tl = ref(null)

watch(
  () => props.show,
  async (show) => {
    if (!tl.value?.isActive()) {
      tl.value = show ? animateIn() : animateOut()
      await tl.value.play()
    }
  },
)

// Animazioni (custom per video Overlay fullscreen)
const animateIn = (params) => {
  const parent = overlay.value
  const background = parent.querySelector('[data-animate="overlay-background"]')
  const content = parent.querySelector('[data-animate="overlay-content"]')

  const tl = gsap.timeline({
    paused: true,
    onUpdate: () => {
      emit('animation-update', tl.progress())
    },
    onComplete: () => {
      tl.kill()
    },
    ...params,
  })

  tl.set(parent, { autoAlpha: 1 })
  tl.set(document.body, { overflow: 'hidden' })
  tl.to(background, {
    scaleX: 1,
  })
  tl.to(content, {
    opacity: 1,
  })

  return tl
}

const animateVideoIn = (params) => {
  const parent = overlay.value
  const content = parent.querySelector('[data-animate="overlay-content"]')

  const tl = gsap.timeline({
    paused: true,
    onUpdate: () => {
      emit('animation-update', tl.progress())
    },
    onComplete: () => {
      tl.kill()
    },
    ...params,
  })

  tl.set(parent, { autoAlpha: 1 })
  tl.set(document.body, { overflow: 'hidden' })
  tl.to(content, {
    opacity: 1,
  })

  return tl
}

const animateOut = (params) => {
  const parent = overlay.value
  const background = parent.querySelector('[data-animate="overlay-background"]')
  const content = parent.querySelector('[data-animate="overlay-content"]')

  const tl = gsap.timeline({
    paused: true,
    onComplete: () => {
      emit('animation-reverse-complete')
      tl.kill()
      gsap.set([parent, background, content], {
        clearProps: 'all',
      })
    },
    ...params,
  })
  tl.to(content, {
    opacity: 0,
  })
  tl.to(background, {
    scaleX: 0,
  })
  tl.set(document.body, { overflow: 'auto' })

  tl.set(parent, { autoAlpha: 0 })

  return tl
}

const animateVideoOut = (params) => {
  const parent = overlay.value
  const content = parent.querySelector('[data-animate="overlay-content"]')

  const tl = gsap.timeline({
    paused: true,
    onComplete: () => {
      emit('animation-reverse-complete')
      tl.kill()
      gsap.set([parent, content], {
        clearProps: 'all',
      })
    },
    ...params,
  })
  tl.to(content, {
    opacity: 0,
  })
  tl.set(document.body, { overflow: 'auto' })
  tl.set(parent, { autoAlpha: 0 })

  return tl
}

defineExpose({
  animateIn,
  animateVideoIn,
  animateOut,
  animateVideoOut,
})
</script>

<style lang="scss">
@import './style.scss';
</style>
