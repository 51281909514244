<template>
  <div class="nav-menu-links" ref="menuLinks" data-animate="menu-links">
    <ul>
      <li>
        <NuxtLink
          class="nav-menu-link link-menu-1"
          :to="localePath({ name: 'piatti-e-vini' })"
          data-animate="menu-link"
          :external="external"
        >
          <span>{{ $t(`menu.menu-nav.piatti-e-vini`) }}</span>
        </NuxtLink>
      </li>
      <li>
        <NuxtLink
          class="nav-menu-link link-menu-1"
          :to="localePath({ name: 'in-cucina' })"
          data-animate="menu-link"
          :external="external"
        >
          <span>{{ $t(`menu.menu-nav.cucina`) }}</span>
        </NuxtLink>
      </li>
      <li>
        <NuxtLink
          class="nav-menu-link link-menu-1"
          :to="localePath({ name: 'in-sala' })"
          data-animate="menu-link"
          :external="external"
        >
          <span>{{ $t(`menu.menu-nav.sala-giardino`) }}</span>
        </NuxtLink>
      </li>
      <li>
        <NuxtLink
          class="nav-menu-link link-menu-1"
          :to="localePath({ name: 'oggi-come-ieri' })"
          data-animate="menu-link"
          :external="external"
        >
          <span>{{ $t(`menu.menu-nav.oggi-ieri`) }}</span>
        </NuxtLink>
      </li>
      <li>
        <NuxtLink
          class="nav-menu-link link-menu-1"
          :to="localePath({ name: 'i-tuoi-eventi' })"
          data-animate="menu-link"
          :external="external"
        >
          <span>{{ $t(`menu.menu-nav.events`) }}</span>
        </NuxtLink>
      </li>
      <li>
        <NuxtLink
          class="nav-menu-link link-menu-1"
          :to="localePath({ name: 'contatti' })"
          data-animate="menu-link"
          :external="external"
        >
          <span>{{ $t(`menu.menu-nav.contacts`) }}</span>
        </NuxtLink>
      </li>
      <li class="prenota">
        <NuxtLink
          class="nav-menu-link link-menu-1"
          :to="localePath({ name: 'prenota' })"
          data-animate="menu-link"
          :external="external"
        >
          <span>{{ $t(`menu.menu-nav.prenota`) }}</span>
        </NuxtLink>
      </li>
      <li class="regala">
        <NuxtLink
          class="nav-menu-link link-menu-1"
          :to="localePath({ name: 'regala-una-cena' })"
          data-animate="menu-link"
          :external="external"
        >
          <span>{{ $t(`menu.menu-nav.regala`) }}</span>
        </NuxtLink>
      </li>
    </ul>

    <div
      class="nav-menu-links__bottom flex is-align-right is-justify-space-between"
    >
      <div v-if="corporate?.socialLinks?.length">
        <div
          class="is-text-uppercase text-sans-xs"
          data-animate="menu-link"
          v-for="social in corporate.socialLinks"
          :key="social.id"
          v-html="social.text"
        />
      </div>

      <div class="nav-menu-links__lang-switch">
        <ul class="nav-menu-links__lang-switch__list flex">
          <li v-for="localeObj in locales" :key="localeObj.code">
            <a
              data-animate="menu-link"
              class="text-sans-xs is-text-uppercase"
              :class="{
                '--active': locale === localeObj.code,
              }"
              :href="switchLocalePath(localeObj.code)"
            >
              <span>{{ localeObj.code }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavigationMenuLinks',
}
</script>

<script setup>
import { gsap } from 'gsap'
const { locale, locales } = useI18n()

const emit = defineEmits(['open-sub-menu', 'open-search'])

const currentLocaleObj = computed(() => {
  return locales.value.find(({ code }) => code === locale.value)
})

const props = defineProps({
  links: Array,
  show: Boolean,
  corporate: Object || null,
  external: {
    type: Boolean,
    default: false,
  },
})

const menuLinks = ref(null)
const tl = ref(null)

// Animazioni
const animateIn = (params) => {
  const parent = menuLinks.value
  const links = parent.querySelectorAll('[data-animate="menu-link"]')

  const tl = gsap.timeline({
    paused: true,
    onComplete: () => {
      tl.kill()
    },
    ...params,
  })
  tl.set(parent, { autoAlpha: 1, position: 'relative' })
  tl.to(links, {
    y: '0%',
    stagger: 0.05,
    opacity: 1,
  })

  return tl
}

const animateOut = (params) => {
  const parent = menuLinks.value
  const links = parent.querySelectorAll('[data-animate="menu-link"]')

  const tl = gsap.timeline({
    paused: true,
    onStart: () => {
      gsap.set(parent, { position: 'absolute' })
    },
    onComplete: () => {
      tl.kill()
      gsap.set([parent, links], {
        clearProps: 'all',
      })
    },
    ...params,
  })
  tl.to(links, {
    y: '101%',
    opacity: 0,
    stagger: 0.05,
  })
  tl.set(parent, { autoAlpha: 0 })

  return tl
}

const openSubmenu = (action) => emit('open-sub-menu', action)

watch(
  () => props.show,
  (show) => {
    if (!tl.value?.isActive()) {
      tl.value = show ? animateIn() : animateOut()
      tl.value.duration(0.5).play()
    }
  },
)

defineExpose({
  animateIn,
  animateOut,
})
</script>

<style lang="scss">
@import './style.scss';
</style>
