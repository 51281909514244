import gql from 'graphql-tag'

import { seoFragment } from '../fragments/seo.js'
import { responsiveImageFragment } from '../fragments/images.js'
import { gridFragment } from '../fragments/dato.js'

export const getRegalaUnaCena = gql`
  ${seoFragment}
  ${responsiveImageFragment}
  ${gridFragment}
  query GetRegalaUnaCena($locale: SiteLocale) {
    regalaUnaCena(locale: $locale) {
      seo {
        ...SeoFragment
      }
      title
      subtitle
      introText
      body {
        __typename
        ... on GiftADinnerSlotRecord {
          id
          slot {
            id
            items {
              id
              body
              linkLabel
              linkUrl
            }
          }
        }
        ... on ContactBlockRecord {
          id
          pretitle
          title
          items {
            id
            title
            linkUrl
            linkLabel
            ctaTheme
          }
          hideBar
          isHighlighted
        }
        ... on IntroBlockRecord {
          id
          introImage {
            id
            url
            width
            height
          }
          introText
          linkLabel
          linkUrl
          videoBackgroundUrl
          videoFullscreenUrl
        }
        ... on GridSectionRecord {
          ...GridFragment
        }
        ... on FullscreenImageRecord {
          id
          smallHeight
          image {
            id
            url
            landscape: responsiveImage(
              imgixParams: { fit: crop, w: 1920, h: 1080, ar: "16/9", fm: webp }
            ) {
              ...ResponsiveImageFragment
            }
            portrait: responsiveImage(
              imgixParams: { fit: crop, w: 1536, h: 2048, ar: "9/16", fm: webp }
            ) {
              ...ResponsiveImageFragment
            }
          }
        }
        ... on MaskTextRecord {
          id
          text
          overlapText
          isDark
        }
        ... on ZoomMasonryRecord {
          id
          images {
            scale
            id
            image {
              id
              responsiveImage(
                imgixParams: { auto: format, q: "60", fit: crop }
              ) {
                ...ResponsiveImageFragment
              }
            }
          }
        }
        ... on ListingMenuListRecord {
          id
          backgroundColor {
            hex
          }
          textColor {
            hex
          }
          list {
            id
            title
            link
            coverImage {
              id
              responsiveImage(
                imgixParams: { auto: format, q: "60", fit: crop }
              ) {
                ...ResponsiveImageFragment
              }
            }
          }
        }
        ... on LinkPanelListRecord {
          id
          panels {
            id
            title
            description
            image {
              id
              responsiveImage(
                imgixParams: { auto: format, q: "60", fit: crop }
              ) {
                ...ResponsiveImageFragment
              }
            }
            linkLabel
            linkUrl
          }
        }
        ... on CardCarouselRecord {
          id
          verticalRatio
          slides {
            id
            linkVideo
            linkVideoPreview
            image {
              id
              responsiveImage(
                imgixParams: { ar: "16:9", fit: crop, w: "2000", fm: webp }
              ) {
                ...ResponsiveImageFragment
              }
            }
            portrait: image {
              id
              responsiveImage(
                imgixParams: { ar: "4:5", fit: crop, w: "2000", fm: webp }
              ) {
                ...ResponsiveImageFragment
              }
            }
          }
        }
        ... on InfoBannerSlotRecord {
          id
          banner {
            id
            title
            description
            linkUrl
            backgroundColor {
              hex
            }
            textColor {
              hex
            }
            image {
              id
              responsiveImage(
                imgixParams: { auto: format, q: "60", fit: crop }
              ) {
                ...ResponsiveImageFragment
              }
            }
            imageMobile {
              id
              responsiveImage(
                imgixParams: { auto: format, q: "60", fit: crop }
              ) {
                ...ResponsiveImageFragment
              }
            }
            videoUrl
          }
        }
      }
    }
  }
`
