<template>
  <div
    class="footer-main-placeholder"
    :style="{ '--height': `${isFixed ? height : 0}px` }"
  />
  <section
    ref="footerBlock"
    class="footer-main"
    :class="{ '--fixed': height > 0 && isFixed }"
  >
    <div class="">
      <!-- Footer Mobile -->
      <FooterSmall :corporate="corporateData" />

      <!-- Footer Desktop -->
      <FooterLarge :corporate="corporateData" />
    </div>
  </section>
</template>

<script>
export default {
  name: 'FooterMain',
}
</script>

<script setup>
import { getCorporateData } from '@/graphql/dato/queries/footer.js'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { debounce } from '~/assets/js/utils.js'
gsap.registerPlugin(ScrollTrigger)

const { locale } = useI18n()
const runtimeConfig = useRuntimeConfig()
const matchMedia = useHelpers().evalMatchMedia('(min-width: 1000px)')
const actualYear = computed(() => new Date().getFullYear())

const isInView = ref(null)
const footerBlock = ref(null)
const isFixed = ref(false)
const height = ref(0)

const hideScrollTop = setHideScrollTop()
const lightTheme = setLightTheme()

const touch = ref(false)
const route = useRoute()

// Corporate data queries (from DatoCms)
const variables = computed(() => ({
  locale: locale?.value ? locale.value : 'it',
}))
const { data } = await useGraphql(getCorporateData, variables)
if (!data.value?.corporate) {
  throw createError({ statusCode: 404 })
}
const corporateData = computed(() =>
  data?.value?.corporate ? data?.value?.corporate : null,
)

onMounted(() => {
  hideScrollTop.value = false

  touch.value = 'ontouchstart' in window || !!navigator.maxTouchPoints /// !! trasformano 0/1 in Bool
  window.addEventListener('resize', debounce(onResize))

  setTimeout(() => {
    onResize()
  }, 500)

  ScrollTrigger.create({
    id: 'scrollFooter',
    start: 'top bottom-=120px',
    end: 'bottom bottom-=60px',
    trigger: footerBlock.value,
    onEnter: () => {
      hideScrollTop.value = true
    },
    onEnterBack: () => {
      hideScrollTop.value = true
    },
    onLeave: () => {
      hideScrollTop.value = false
    },
    onLeaveBack: () => {
      hideScrollTop.value = false
    },
  })
})

onUnmounted(() => {
  window.removeEventListener('resize', debounce(onResize), false)

  ScrollTrigger.refresh()
  hideScrollTop.value = false

  setTimeout(() => {
    ScrollTrigger.refresh()
  }, 800)
})

watch(
  () => route.path,
  () => {
    setTimeout(() => {
      ScrollTrigger.refresh()
      hideScrollTop.value = false
      ScrollTrigger.refresh()
    }, 800)
  },
)

const onResize = () => {
  if (footerBlock.value) {
    height.value = footerBlock.value.offsetHeight
    isFixed.value = footerBlock.value.offsetHeight < window.innerHeight
  }
}
</script>

<style lang="scss">
@import './style.scss';
</style>
