<template>
  <NuxtLink
    data-no-cursor
    :to="props.link"
    :class="{
      'icon-link text-sans-link': !isSecondary,
      'icon-link-2 text-sans-link-secondary': isSecondary,
      '--is-light': theme === 'light',
    }"
  >
    <span
      :class="{
        'link-underline-reverse-1': isAnimationReverse,
        'link-underline-1': !isAnimationReverse,
      }"
    >
      {{ props.label }}
    </span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="31.598"
      height="18.309"
      viewBox="0 0 31.598 18.309"
      :alt="props.label"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rettangolo_669"
            data-name="Rettangolo 669"
            width="31.598"
            height="18.309"
            transform="translate(61.72 -1)"
            fill="#5c5743"
          />
        </clipPath>
      </defs>
      <g
        id="freccia"
        transform="translate(-61.72 1)"
        clip-path="url(#clip-path)"
      >
        <path
          id="Tracciato_292"
          data-name="Tracciato 292"
          d="M98.754,3.483A9.018,9.018,0,0,1,97.881,0H95.813A9.022,9.022,0,0,0,96.7,3.572a7.717,7.717,0,0,0,2.57,2.495c.271.171-.292-.131,0,0H0V8.023H99.272c-.292.133.27-.174,0,0a7.673,7.673,0,0,0-2.559,2.495,9.071,9.071,0,0,0-.889,3.564h2.069a9.018,9.018,0,0,1,.872-3.483c.964-2.1,3.1-2.667,5.41-2.575V6.067c-2.309.092-4.446-.483-5.41-2.584"
          transform="translate(-10.843 1.112)"
          fill="#5c5743"
        />
      </g>
    </svg>
  </NuxtLink>
</template>

<script>
export default {
  name: 'WidgetArrowLink',
}
</script>

<script setup>
const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  link: {
    type: String,
    default: '',
  },
  isAnimationReverse: {
    type: Boolean,
    default: true,
  },
  isSecondary: {
    type: Boolean,
    default: false,
  },
  theme: {
    type: String,
    default: 'dark',
  },
})
</script>

<style lang="scss">
@import './style.scss';
</style>
