import { gql } from 'graphql-tag'
import { seoFragment } from '../fragments/seo.js'
// import { responsiveImageFragment } from '../fragments/images'

export const bookData = gql`
  ${seoFragment}
  query BookData($locale: SiteLocale) {
    corporate(locale: $locale) {
      id
      generalInfo
      additionalInfo
      bookingInfo {
        id
        text
      }
      socialLinks {
        id
        text
      }
    }
    book(locale: $locale) {
      seo {
        ...SeoFragment
      }
      title
      subtitle
      note
      chiusureRicorrenti {
        sezioneGiorno {
          id
          valore
          nome
          chiusuraCompleta
          chiusoPranzo
          chiusoCena
        }
      }
      eccezioni {
        id
        dataInizio
        dataFine
        chiusuraCompleta
        chiusoPranzo
        chiusoCena
        aperturaCompleta
        apertoPranzo
        apertoCena
      }
    }
  }
`
