<template>
  <div class="footer-desk show-for-large">
    <!-- Riga Menu principale, logo, social ecc -->
    <div class="footer-main-row flex row-190-40">
      <div data-no-cursor class="footer-col logo">
        <NuxtLink :to="localePath({ name: 'index' })" class="logo-link">
          <img
            src="@/assets/img/logos/logo-negative.svg"
            :alt="runtimeConfig.public.siteName"
          />
        </NuxtLink>

        <div class="footer-main-row__lang-switch">
          <ul class="footer-main-row__lang-switch__list flex text-corpo-l">
            <li v-for="localeObj in locales" :key="localeObj.code">
              <p v-if="locale === localeObj.code">
                <span>{{ localeObj.name }}</span>
              </p>
              <!-- https://i18n.nuxtjs.org/docs/guide/lang-switcher -->
              <a
                v-if="locale !== localeObj.code"
                class="link-underline-reverse-1"
                @click.prevent.stop="setLocale(localeObj.code)"
                href="#"
              >
                <span>{{ localeObj.name }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer-col main-menu flex text-corpo-l">
        <div class="main-menu-left">
          <ul>
            <li>
              <NuxtLink
                data-no-cursor
                class="main-menu-left-link link-underline-reverse-1"
                :to="localePath({ name: 'piatti-e-vini' })"
              >
                <span>{{ $t(`menu.menu-nav.piatti-e-vini`) }}</span>
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                data-no-cursor
                class="main-menu-left-link link-underline-reverse-1"
                :to="localePath({ name: 'in-cucina' })"
              >
                <span>{{ $t(`menu.menu-nav.cucina`) }}</span>
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                data-no-cursor
                class="main-menu-left-link link-underline-reverse-1"
                :to="localePath({ name: 'in-sala' })"
              >
                <span>{{ $t(`menu.menu-nav.sala-giardino`) }}</span>
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                data-no-cursor
                class="main-menu-left-link link-underline-reverse-1"
                :to="localePath({ name: 'i-tuoi-eventi' })"
              >
                <span>{{ $t(`menu.menu-nav.events`) }}</span>
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                data-no-cursor
                class="main-menu-left-link link-underline-reverse-1"
                :to="localePath({ name: 'oggi-come-ieri' })"
              >
                <span>{{ $t(`menu.menu-nav.oggi-ieri`) }}</span>
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                data-no-cursor
                class="main-menu-left-link link-underline-reverse-1"
                :to="localePath({ name: 'contatti' })"
              >
                <span>{{ $t(`menu.menu-nav.contacts`) }}</span>
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                data-no-cursor
                class="main-menu-left-link link-underline-reverse-1"
                :to="localePath({ name: 'lavora-con-noi' })"
              >
                <span>{{ $t(`menu.menu-nav.work-with-us`) }}</span>
              </NuxtLink>
            </li>
          </ul>
        </div>
        <div class="main-menu-right flex is-justify-space-between">
          <div v-if="corporate?.bookingInfo?.length" class="footer-dato-links">
            <p>{{ $t('cta.book') }}</p>
            <div
              data-no-cursor
              v-for="book in corporate.bookingInfo"
              :key="book.id"
              v-html="book.text"
            />
          </div>

          <div
            v-if="corporate?.socialLinks?.length"
            class="footer-dato-links socials"
          >
            <p>{{ $t('footer.main.social') }}</p>
            <div class="flex">
              <div
                data-no-cursor
                v-for="social in corporate.socialLinks"
                :key="social.id"
                v-html="social.text"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Riga Info -->
    <div class="footer-main-row main-info flex row-190-40">
      <div class="footer-col info">
        <div
          data-no-cursor
          v-if="corporate?.generalInfo"
          class="text-corpo-l"
          v-html="corporate.generalInfo"
        />
      </div>

      <div class="footer-col extra">
        <div
          v-if="corporate?.additionalInfo"
          class="text-corpo-m"
          v-html="corporate.additionalInfo"
        />
      </div>
    </div>

    <!-- Riga corporate e associati -->
    <div class="footer-main-row row-40-25 flex text-sans-s corporate">
      <div class="footer-col">
        <div class="footer-main-corporate flex">
          <p v-html="$t('footer.main.copyright', { year: actualYear })" />
          <nuxt-link
            data-no-cursor
            class="link-underline-reverse-1"
            :to="
              localePath({
                name: 'privacy-policy',
              })
            "
            ><span>{{ $t('footer.main.privacy') }}</span>
          </nuxt-link>
          <nuxt-link
            data-no-cursor
            class="link-underline-reverse-1"
            :to="
              localePath({
                name: 'cookie-policy',
              })
            "
            ><span>Cookie Policy</span>
          </nuxt-link>
        </div>
      </div>
      <div class="footer-col footer-credits">
        <p>
          Credits:
          <a
            data-no-cursor
            class="link-underline-reverse-1"
            target="_blank"
            rel="noopener"
            href="https://www.quamm.it/"
            >Quamm</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterLarge',
}
</script>

<script setup>
const runtimeConfig = useRuntimeConfig()
const actualYear = computed(() => new Date().getFullYear())

const switchLocalePath = useSwitchLocalePath()
const { locale, locales, setLocale } = useI18n()

const props = defineProps({
  corporate: Object || null,
})
</script>
