<template>
  <div class="nav-large__wrap flex is-justify-space-between is-align-center">
    <!-- Logo -->
    <NuxtLink
      data-no-cursor
      :to="localePath({ name: 'index' })"
      :external="external"
      class="nav-large__logo"
    >
      <img
        :src="isLight ? GlyphLogoWhite : GlyphLogo"
        class="nav-large__logo-glyph"
        :alt="runtimeConfig.public.siteName"
      />
    </NuxtLink>

    <!-- Menu Left -->
    <div class="nav-large__list__wrap" @mouseleave="mouseLeave">
      <ul ref="nav" class="nav-large__list flex is-align-center">
        <li class="nav-large__list-main-link">
          <nuxt-link
            data-no-cursor
            :to="localePath({ name: 'piatti-e-vini' })"
            class="text-nav"
            :external="external"
            :class="{ '--active': route.name?.includes('piatti-e-vini') }"
            >{{ $t('menu.menu-nav.piatti-e-vini') }}</nuxt-link
          >
        </li>
        <li class="nav-large__list-main-link">
          <nuxt-link
            data-no-cursor
            :to="localePath({ name: 'in-cucina' })"
            class="text-nav"
            :external="external"
            >{{ $t('menu.menu-nav.cucina') }}</nuxt-link
          >
        </li>
        <li class="nav-large__list-main-link">
          <nuxt-link
            data-no-cursor
            :to="localePath({ name: 'in-sala' })"
            class="text-nav"
            :external="external"
            >{{ $t('menu.menu-nav.sala-giardino') }}</nuxt-link
          >
        </li>
      </ul>
    </div>

    <!-- Menu Right -->
    <div class="nav-large__list__wrap" @mouseleave="mouseLeave">
      <ul ref="nav" class="nav-large__list flex is-align-center">
        <li class="nav-large__list-main-link">
          <nuxt-link
            data-no-cursor
            :to="localePath({ name: 'oggi-come-ieri' })"
            class="text-nav"
            :external="external"
            >{{ $t('menu.menu-nav.oggi-ieri') }}</nuxt-link
          >
        </li>
        <li class="nav-large__list-main-link">
          <nuxt-link
            data-no-cursor
            :to="localePath({ name: 'i-tuoi-eventi' })"
            class="text-nav"
            :external="external"
            >{{ $t('menu.menu-nav.events') }}</nuxt-link
          >
        </li>
        <li class="nav-large__list-main-link">
          <nuxt-link
            data-no-cursor
            :to="localePath({ name: 'contatti' })"
            class="text-nav"
            :external="external"
            >{{ $t('menu.menu-nav.contacts') }}</nuxt-link
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavigationMenuLarge',
}
</script>

<script setup>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import GlyphLogo from '@/assets/img/logos/logo-positive.svg'
import GlyphLogoWhite from '@/assets/img/logos/logo-negative.svg'

gsap.registerPlugin(ScrollTrigger)

const runtimeConfig = useRuntimeConfig()
const route = useRoute()

const lenis = inject('lenis')

const nav = ref(null)
const logo = ref(null)
const duration = 0.4
const isHover = ref(false)
const isNewPage = ref(true)

const props = defineProps({
  isLight: Boolean,
  external: {
    type: Boolean,
    default: false,
  },
})

watch(
  () => isHover.value,
  (isHover) => {
    if (isHover) {
      // animateMenuIn().play()
    } else {
      if (lenis.value.scroll > 100 && !isNewPage.value) {
        // animateMenuOut().play()
      }
    }
  },
)

watch(
  () => route.path,
  () => {
    isNewPage.value = true
  },
)

onMounted(() => {
  isNewPage.value = false
})

const mouseLeave = () => {
  isHover.value = false
}

const navigate = () => {
  isHover.value = false
}
</script>

<style lang="scss">
@import './style.scss';
</style>
