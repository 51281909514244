import gql from 'graphql-tag'
import { responsiveImageFragment } from '../fragments/images.js'

export const gridFragment = gql`
  ${responsiveImageFragment}
  fragment GridFragment on GridSectionRecord {
    id
    anchorHtml
    pretitle
    title
    hideSection
    backgroundColor {
      hex
    }
    textColor {
      hex
    }
    gridRows {
      id
      items {
        ... on ContactBlockRecord {
          id
          pretitle
          title
          items {
            id
            title
            linkUrl
            linkLabel
            ctaTheme
          }
          hideBar
        }
        ... on MaskCarouselRecord {
          id
          pretitle
          title
          backgroundColor {
            hex
          }
          textColor {
            hex
          }
          images {
            id
            url
            landscape: responsiveImage(
              imgixParams: { fit: crop, w: 1920, h: 1080, ar: "16/9", fm: webp }
            ) {
              ...ResponsiveImageFragment
            }
            portrait: responsiveImage(
              imgixParams: { fit: crop, w: 1536, h: 2048, ar: "9/16", fm: webp }
            ) {
              ...ResponsiveImageFragment
            }
            preview: responsiveImage(
              imgixParams: { fit: crop, w: 300, h: 150, fm: webp }
            ) {
              ...ResponsiveImageFragment
            }
          }
        }
        ... on GridBlockItemRecord {
          id
          sideText
          smallHeight
          linkVideoPreview
          linkVideo
          fullWidth
          title
          description
          linkLabel
          linkUrl
          isMedium
          isSmall
          isVertical
          isRight
          isCenter
          hideMediaOnMobile
          image {
            id
            responsiveImage(imgixParams: { auto: format, q: "60", fit: crop }) {
              ...ResponsiveImageFragment
            }
          }
          icon {
            url
          }
        }
        ... on GridBlockItemTextRecord {
          id
          text
          linkLabel
          linkUrl
          ctaTheme
          isLeftAlign
        }
        ... on TwoColumnsGridTextRecord {
          id
          title
          text
          titleAsSimpleText
        }
      }
    }
  }
`
