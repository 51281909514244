
// @ts-nocheck


export const localeCodes =  [
  "it",
  "en"
]

export const localeLoaders = {
  "it": [],
  "en": []
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config",
  "locales": [
    {
      "code": "it",
      "site": "it",
      "language": "it-IT",
      "name": "Italiano"
    },
    {
      "code": "en",
      "site": "en",
      "language": "en-US",
      "name": "English"
    }
  ],
  "defaultLocale": "it",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "https://www.ristorantedaguido.com",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "i-tuoi-eventi": {
      "it": "/i-tuoi-eventi",
      "en": "/your-events"
    },
    "in-cucina": {
      "it": "/in-cucina",
      "en": "/into-the-kitchen"
    },
    "in-sala": {
      "it": "/in-sala",
      "en": "/indoors"
    },
    "oggi-come-ieri": {
      "it": "/oggi-come-ieri",
      "en": "/today-as-yesterday"
    },
    "contatti/index": {
      "it": "/contatti",
      "en": "/contacts"
    },
    "contatti/form-success/index": {
      "it": "/contatti/modulo-inviato",
      "en": "/contacts/form-sent"
    },
    "contatti/form-failure/index": {
      "it": "/contatti/errore-di-invio",
      "en": "/contacts/submission-error"
    },
    "piatti-e-vini/index": {
      "it": "/piatti-e-vini",
      "en": "/dishes-and-wines"
    },
    "piatti-e-vini/[category]/index": {
      "it": "/piatti-e-vini/[category]",
      "en": "/dishes-and-wines/[category]"
    },
    "piatti-e-vini/[category]/[slug]/index": {
      "it": "/piatti-e-vini/[category]/[slug]",
      "en": "/dishes-and-wines/[category]/[slug]"
    },
    "take-away/index": {
      "it": "/take-away",
      "en": "/take-away"
    },
    "regala-una-cena": {
      "it": "/regala-una-cena",
      "en": "/gift-a-dinner"
    },
    "/gift-voucher-ok": {
      "it": "/gift-voucher-ok",
      "en": "/gift-voucher-ok"
    },
    "event/[slug]/index": {
      "it": "/event/[slug]",
      "en": "/event/[slug]"
    },
    "prenota/index": {
      "it": "/prenota",
      "en": "/book-online"
    },
    "prenota/form-success/index": {
      "it": "/prenota/modulo-inviato",
      "en": "/book/form-sent"
    },
    "prenota/form-failure/index": {
      "it": "/prenota/errore-di-invio",
      "en": "/book/submission-error"
    },
    "lavora-con-noi/index": {
      "it": "/lavora-con-noi",
      "en": "/work-with-us"
    },
    "lavora-con-noi/form-success/index": {
      "it": "/lavora-con-noi/modulo-inviato",
      "en": "/work-with-us/form-sent"
    },
    "lavora-con-noi/form-failure/index": {
      "it": "/lavora-con-noi/errore-di-invio",
      "en": "/work-with-us/submission-error"
    },
    "index": {
      "it": "/",
      "en": "/"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "it",
    "site": "it",
    "language": "it-IT",
    "name": "Italiano",
    "files": []
  },
  {
    "code": "en",
    "site": "en",
    "language": "en-US",
    "name": "English",
    "files": []
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
