<template>
  <div class="footer-mobile hide-for-large row-190-40">
    <div class="footer-col logo">
      <NuxtLink class="logo-link" :to="localePath({ name: 'index' })">
        <img
          src="@/assets/img/logos/logo-negative.svg"
          :alt="runtimeConfig.public.siteName"
        />
      </NuxtLink>
    </div>

    <div class="footer-main-row__lang-switch">
      <ul class="footer-main-row__lang-switch__list flex text-corpo-l-2">
        <li v-for="localeObj in locales" :key="localeObj.code">
          <p v-if="locale === localeObj.code">
            <span>{{ localeObj.name }}</span>
          </p>
          <a
            v-if="locale !== localeObj.code"
            class="link-underline-reverse-1"
            :href="switchLocalePath(localeObj.code)"
          >
            <span>{{ localeObj.name }}</span>
          </a>
        </li>
      </ul>
    </div>

    <div
      v-if="corporate?.bookingInfo?.length"
      class="text-corpo-l footer-dato-links"
    >
      <p>{{ $t('cta.book') }}</p>
      <div
        v-for="book in corporate.bookingInfo"
        :key="book.id"
        v-html="book.text"
      />
    </div>

    <div v-if="corporate?.additionalInfo" class="footer-col extra">
      <div class="text-corpo-m" v-html="corporate.additionalInfo" />
    </div>

    <div class="main-menu-left text-corpo-l footer-dato-links">
      <p>{{ $t('menu.menu-nav.contacts') }}</p>
      <div class="">
        <ul>
          <li>
            <NuxtLink
              class="main-menu-left-link link-underline-reverse-1"
              :to="localePath({ name: 'contatti' })"
            >
              <span>{{ $t(`footer.main.inviaRichiesta`) }}</span>
            </NuxtLink>
          </li>
          <li class="m-top-8px">
            <NuxtLink
              class="main-menu-left-link link-underline-reverse-1"
              :to="localePath({ name: 'lavora-con-noi' })"
            >
              <span>{{ $t(`menu.menu-nav.work-with-us`) }}</span>
            </NuxtLink>
          </li>
        </ul>
      </div>
    </div>

    <div
      v-if="corporate?.socialLinks?.length"
      class="text-corpo-l footer-dato-links"
    >
      <p>{{ $t('footer.main.social') }}</p>
      <div
        v-for="social in corporate.socialLinks"
        :key="social.id"
        v-html="social.text"
      />
    </div>

    <div v-if="corporate?.generalInfo" class="footer-col info">
      <div v-html="corporate?.generalInfo" />
    </div>

    <div class="text-sans-s corporate p-top-16px p-bottom-16px">
      <div class="footer-main-corporate p-bottom-16px">
        <p v-html="$t('footer.main.copyright', { year: actualYear })" />
      </div>
      <div class="footer-credits flex is-justify-space-between p-top-16px">
        <nuxt-link
          class="link-underline-reverse-1"
          :to="
            localePath({
              name: 'privacy-policy',
            })
          "
          ><span>{{ $t('footer.main.privacy') }}</span>
        </nuxt-link>
        <nuxt-link
          class="link-underline-reverse-1"
          :to="
            localePath({
              name: 'cookie-policy',
            })
          "
          ><span>Cookie Policy</span>
        </nuxt-link>
        <p>
          Credits:
          <a
            class="link-underline-reverse-1"
            target="_blank"
            rel="noopener"
            href="https://www.quamm.it/"
            >Quamm</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterSmall',
}
</script>

<script setup>
const runtimeConfig = useRuntimeConfig()
const actualYear = computed(() => new Date().getFullYear())

const switchLocalePath = useSwitchLocalePath()
const { locale, locales } = useI18n()

const props = defineProps({
  corporate: Object || null,
})
</script>
