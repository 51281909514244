import * as dato from './dato.js'
import * as home from './home.js'
import * as cucina from './cucina.js'
import * as sala from './sala.js'
import * as storia from './storia.js'
import * as piattiVini from './piatti-vini.js'
import * as eventi from './eventi.js'
import * as regala from './regala.js'
import * as projects from './projects.js'
import * as expertise from './expertise.js'
import * as technology from './technology.js'
import * as corporates from './corporates.js'
import * as studio from './studio.js'
import * as prenota from './prenota.js'
import * as navigation from './navigation.js'
import * as letsTalk from './lets-talk.js'
import * as sitemap from './sitemap.js'
import * as footer from './footer.js'

export default {
  dato,
  home,
  cucina,
  sala,
  storia,
  piattiVini,
  eventi,
  regala,
  projects,
  expertise,
  technology,
  corporates,
  prenota,
  studio,
  navigation,
  letsTalk,
  sitemap,
  footer,
}
