<template>
  <div v-if="!touch" class="custom-cursor" ref="cursor">
    <div class="custom-cursor__inner --link" data-animate="cursor-inner"></div>
  </div>
</template>

<script>
export default {
  name: 'WidgetMouseCursor',
}
</script>

<script setup>
import { gsap } from 'gsap'

const route = useRoute()

const touch = ref(false)
const cursor = ref(null)

onMounted(() => {
  touch.value = 'ontouchstart' in window || navigator.maxTouchPoints

  if (!touch.value && cursor.value) {
    onHover(cursor.value)
    cursorPosition(cursor.value)
  }
})

const onHover = (cursor) => {
  const duration = 0.4
  const tl = gsap.timeline()

  const inner = cursor.querySelector('[data-animate="cursor-inner"]')
  const label = cursor.querySelector('[data-animate="cursor-label"]')

  document.addEventListener('mouseover', (event) => {
    // Qua ragionare al contrario, cioè sempre visibile il pallino tranne quando è vietato...
    const targetHoverable = event.target.closest('[data-hoverable]')
    const targetNoCursor = event.target.closest('[data-no-cursor]')

    if (targetHoverable) {
      const text = targetElement.dataset.hoverable
      label.innerHTML = text
      cursor.classList?.add('--active')
      tl.clear()
      tl.to(cursor, { scale: 1, duration }, 'start')
      tl.to(inner, { scale: 1, opacity: 0.25, duration }, 'start')
      tl.to(label, { y: '0%', duration }, 'start+=65%')
    } else {
      if (targetNoCursor) {
        tl.clear()
        tl.to(inner, { scale: 0, opacity: 0, duration }, 'start')
        tl.to(cursor, { scale: 0, duration }, 'start')
      } else {
        tl.clear()
        tl.to(cursor, { scale: 1, duration }, 'start')
        tl.to(inner, { scale: 1, opacity: 1, duration }, 'start')
      }
    }
  })

  document.addEventListener('mouseout', (event) => {
    const relatedTargetElement = event.relatedTarget
    const targetHoverable = event.target.closest('[data-hoverable]')
    if (
      targetHoverable &&
      (!relatedTargetElement || !targetHoverable.contains(relatedTargetElement))
    ) {
      tl.clear()
      tl.to(label, { y: '101%', duration }, 'start')
      tl.to(inner, { scale: 0, opacity: 0, duration }, 'start+=65%')
      tl.to(cursor, { scale: 0, duration }, 'start+=65%')
      cursor.classList?.remove('--active')
    }
  })
}

// TODO: Migliorare questa ridondanza di animazione
watch(
  () => route.path,
  () => {
    if (cursor.value) {
      const duration = 0.3
      const tl = gsap.timeline()
      cursor.value.classList?.remove('--active')
      const inner = cursor.value.querySelector('[data-animate="cursor-inner"]')
      const label = cursor.value.querySelector('[data-animate="cursor-label"]')
      tl.to(label, { y: '101%', duration }, 'start')
      tl.to(inner, { scale: 0, opacity: 0, duration }, 'start+=65%')
      tl.to(cursor.value, { scale: 0, duration }, 'start+=65%')
    }
  },
)

const cursorPosition = (cursor) => {
  const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 }
  const mouse = { x: pos.x, y: pos.y }
  const speed = 0.2

  const xSet = gsap.quickSetter(cursor, 'x', 'px')
  const ySet = gsap.quickSetter(cursor, 'y', 'px')

  window.addEventListener('mousemove', (e) => {
    mouse.x = e.x
    mouse.y = e.y
  })

  gsap.ticker.add(() => {
    // adjust speed for higher refresh monitors
    const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio())

    pos.x += (mouse.x - pos.x) * dt
    pos.y += (mouse.y - pos.y) * dt
    xSet(pos.x)
    ySet(pos.y)
  })
}
</script>

<style lang="scss">
@import './style.scss';
</style>
