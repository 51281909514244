export const useMenuUtils = () => {
  const { locale, t } = useI18n()

  // Di seguito abbiamo una mappa hardcoded delle categorie da gestionale, necessaria per ordinare i menu con prodotti sciolti
  const menuCategoriesMap = [
    { idCategoria: 0 }, // Root
    { idCategoria: 2 }, // Antipasti
    { idCategoria: 14 }, // Primi
    { idCategoria: 15 }, // Secondi
    { idCategoria: 8 }, // Contorni
    { idCategoria: 10 }, // Dolci
    { idCategoria: 6 }, // Caffetteria
    { idCategoria: null }, // I prodotti non mappati li spediamo in fondo alla lista
  ]

  const buildCategoriesTree = (data) => {
    // Helper per generare uno slug da una stringa
    function generateSlug(str) {
      return str
        .toLowerCase() // Convertire in minuscolo
        .replace(/[^\w\s-]/g, '') // Rimuovere caratteri speciali
        .replace(/\s+/g, '-') // Sostituire spazi con trattini
        .replace(/-+/g, '-') // Rimuovere trattini multipli consecutivi
    }

    // Estrarre le categorie dal JSON
    const categorie = data.categorie

    // Creare una mappa degli elementi per id
    const categorieById = {}
    categorie.forEach((item) => {
      categorieById[item.id] = {
        ...item,
        children: [],
        'slug-it': generateSlug(item.nomeIT || ''), // Slug basato su nomeIT
        'slug-en': generateSlug(item.nomeEN || ''), // Slug basato su nomeEN
      }
    })

    // La radice dell'albero
    let root = []

    // Costruire l'albero
    categorie.forEach((item) => {
      if (item.idpadre === null) {
        // Se non ha un idpadre, è una radice
        root.push(categorieById[item.id])
      } else {
        // Aggiungere l'elemento alla chiave "children" del padre
        if (categorieById[item.idpadre]) {
          categorieById[item.idpadre].children.push(categorieById[item.id])
        }
      }
    })

    return root
  }

  // Funzione utility per estrarre la stringa degli allergeni ed ordinarli in ordine crescente
  const extractAllergiesString = (string = '') => {
    const rawArray = string.split(',')
    const mappedArray = rawArray.map(
      (allergyString) => allergyString.split('.')[0],
    )
    const filteredArray = mappedArray.filter((el) => !el.includes('lattosio'))
    const sortedArray = filteredArray.sort((a, b) => {
      return Number(a) - Number(b)
    })
    return sortedArray.join()
  }

  /**
   * Funzione per cercare un nodo nell'albero dato uno slug
   * @param {Array} tree - L'albero costruito con buildTree
   * @param {string} slug - Lo slug da cercare
   * @returns {Object|null} - L'oggetto trovato o null se non esiste
   */
  const findNodeBySlug = (tree, slug, parentId) => {
    // Helper per iterare l'albero in modo ricorsivo
    function search(node) {
      // Controlla se lo slug corrisponde a 'slug-it' o 'slug-en'
      if (
        node['idpadre'] === parentId &&
        (node['slug-it'] === slug || node['slug-en'] === slug)
      ) {
        return node
      }
      // Itera tra i figli se esistono
      for (const child of node.children) {
        const result = search(child)
        if (result) return result
      }
      return null // Nessuna corrispondenza trovata
    }

    // Itera tra tutti i nodi della radice
    for (const rootNode of tree) {
      const result = search(rootNode)
      if (result) return result
    }
    return null // Nessuna corrispondenza trovata nell'intero albero
  }

  /**
   * Funzione per cercare un nodo nell'albero dato un id
   * @param {Array} tree - L'albero costruito con buildTree
   * @param {string} id - L'Id da cercare
   * @returns {Object|null} - L'oggetto trovato o null se non esiste
   */
  const findNodeById = (tree, id) => {
    // Helper per iterare l'albero in modo ricorsivo
    function search(node) {
      // Controlla se lo slug corrisponde al param id
      if (node['id'] == id) {
        return node
      }
      // Itera tra i figli se esistono
      for (const child of node.children) {
        const result = search(child)
        if (result) return result
      }
      return null // Nessuna corrispondenza trovata
    }

    // Itera tra tutti i nodi della radice
    for (const rootNode of tree) {
      const result = search(rootNode)
      if (result) return result
    }
    return null // Nessuna corrispondenza trovata nell'intero albero
  }

  /**
   * Funzione per aggiungere prodotti all'albero
   * @param {Array} tree - L'albero costruito con buildTree
   * @param {Array} products - L'elenco dei prodotti da associare
   * @returns {Array} - L'albero aggiornato con la chiave 'products'
   */
  const addProductsToTree = (tree, products) => {
    // Helper per iterare l'albero in modo ricorsivo
    function attachProducts(node) {
      // Aggiungi i prodotti corrispondenti
      node.products = products.filter(
        (product) => product.idcategoriapadre === node.id,
      )

      // Itera sui figli
      node.children.forEach((child) => attachProducts(child))
    }

    // Itera sui nodi della radice
    tree.forEach((rootNode) => attachProducts(rootNode))

    return tree
  }

  /**
   * Raggruppa i prodotti in base alla chiave 'rifmarcafornitore'
   * @param {Array} products - Array di prodotti
   * @returns {Object} - Oggetto con chiavi uguali ai valori unici di 'rifmarcafornitore' e valori come array di prodotti
   */
  function groupProductsBySupplier(products) {
    return products.reduce((acc, product) => {
      const supplier = product.rifmarcafornitore
      if (!acc[supplier]) {
        acc[supplier] = [] // Crea un array per il fornitore se non esiste
      }
      acc[supplier].push(product) // Aggiungi il prodotto al gruppo del fornitore
      return acc
    }, {})
  }

  // Parsiamo l'array dei menu 'custom' dal gestionale, eliminiamo quelli senza prodotti e taggandoli per tipo
  const buildMenusCustom = (menus = []) => {
    const validMenus = menus.filter((menu) => menu.prodotti?.length)
    return validMenus.map((menu) => {
      const menuType = menu.prezzo ? 'prezzo-fisso' : 'a-prodotti'
      return { ...menu, menuType }
    })
  }

  const extractNodeName = (node, type = 'product') => {
    const actualLocale = locale.value.toUpperCase()
    const importedLabel = node['descrizione']?.trim()

    if (type === 'wine') {
      const actualDescr = node['descr' + actualLocale]
        ? node['descr' + actualLocale]
        : node['descrIT']
      const actualNome = node['nome' + actualLocale]
        ? node['nome' + actualLocale]
        : node['nomeIT']

      return actualDescr ? actualDescr : actualNome
    }

    if (type === 'wine-category' || type === 'title') {
      return node['nome' + actualLocale]
        ? node['nome' + actualLocale]
        : node['nomeIT']
    }

    if (type === 'carta-category') {
      return t('menu.cart.categories["' + node.nome + '"]')
    }

    return node['descr' + actualLocale]
      ? node['descr' + actualLocale]
      : node['descrIT']
  }

  const extractNodeSlug = (node) => {
    const actualLocale = locale.value
    return node['slug-' + actualLocale]
      ? node['slug-' + actualLocale]
      : node['slug-it']
  }

  // Funzione che estrare la categoria del nodo padre da un nodo. Se non presente, va ad estrarre quell'ID dalla lista prodotti originaria
  const extractNodeParentId = (node, allProducts = []) => {
    if (node.idcategoriapadre) {
      return node.idcategoriapadre
    }

    const rawProduct = allProducts.find(
      (product) => product.idprodotto === node.idprodotto,
    )
    return rawProduct ? rawProduct.idcategoriapadre : null
  }

  // Funzione che estrare la lista allergeni del nodo prodotto. Se non presente, va ad estrarla dalla lista prodotti originaria
  const extractNodeAllergies = (node = {}, allProducts = []) => {
    if (node.allergeni) {
      return extractAllergiesString(node.allergeni)
    }

    const rawProduct = allProducts.find(
      (product) => product.idprodotto === node.idprodotto,
    )

    const rawAllergies = rawProduct?.allergeni
      ? extractAllergiesString(rawProduct.allergeni)
      : ''
    return rawAllergies
  }

  // Funzione che ordina una lista di prodotti 'sciolta' e la ordina rispetto all'ID della categoria padre dei singoli prodotti
  const orderMixedProducts = (products = [], allProducts = []) => {
    const productsArr = [...products]

    return productsArr.sort((a, b) => {
      const productAId = extractNodeParentId(a, allProducts)
      const productBId = extractNodeParentId(b, allProducts)

      const productAPosition = a.position || 0
      const productBPosition = b.position || 0

      if (productAPosition < productBPosition) {
        return -1
      }
      if (productAPosition > productBPosition) {
        return 1
      }

      // ID categoria padre sono uguali
      return 0
    })
  }

  // Funzione che inizializza il Menu alla carta prendendo i piatti da vari menu
  const createCartMenu = (menus = []) => {
    const copiedMenus = [...menus]
    const cartMenuCategoriesMap = [
      'M.antipasti',
      'M.primi',
      'M.secondi',
      'M.vegetariano',
    ]
    const cartMenuObj = {
      nome: 'M.carta',
      children: [],
    }

    cartMenuCategoriesMap.forEach((menuCat) => {
      const matchedMenu = menus.find((menu) => menu.nome === menuCat)
      const parsedMenu = { ...matchedMenu, products: matchedMenu.prodotti }
      if (matchedMenu) {
        cartMenuObj.children.push(parsedMenu)
      }
    })

    copiedMenus.push(cartMenuObj)

    return copiedMenus
  }

  return {
    buildCategoriesTree,
    findNodeBySlug,
    findNodeById,
    addProductsToTree,
    groupProductsBySupplier,
    extractNodeName,
    extractNodeSlug,
    extractNodeAllergies,
    extractNodeParentId,
    orderMixedProducts,
    buildMenusCustom,
    createCartMenu,
  }
}
